<!-- 自提详情 -->
<template>
  <div style="padding: 10px 0 0 10px;box-sizing: border-box;">
    <!-- <back /> -->
    <el-button style="margin-left: 9px;" plain type="primary" icon="el-icon-arrow-left" @click="goBack"> 返回 </el-button>
    <div class="det" v-loading="loading">
      <!-- 基本信息 -->
      <div class="detail">
        <h4 class="title">详情信息：</h4>
        <!-- 线下订单-销售 /退款-->
        <div v-if="basicInfo?.orderSource == '1'">
          <el-row class="row">
            <el-col :span="8">
              <div class="col"><span class="txt">订单编号:</span>{{ basicInfo?.orderNum }}</div>
              <div class="col"><span class="txt">结算类型:</span>{{ basicInfo?.orderType == '1' ? '销售' : '退货' }}</div>
              <div class="col"><span class="txt">实收金额(元):</span>{{ basicInfo?.payAmount }}</div>

            </el-col>
            <el-col :span="8">
              <div class="col"><span class="txt">订单时间:</span>{{ basicInfo?.orderTime }}</div>
              <div class="col"><span class="txt">支付类型:</span>{{ getOrderTypeText(basicInfo?.payType) }}</div>
              <div class="col"><span class="txt">进货金额(元):</span>{{ basicInfo?.supplyAmount }}</div>
            </el-col>
            <el-col :span="8">
              <div class="col"><span class="txt">订单类型:</span>{{ basicInfo?.orderSource == '1' ? '线下订单' : '线上订单' }}</div>
              <div class="col"><span class="txt">订单金额(元):</span>{{ basicInfo?.orderAmount }}</div>
              <div class="col"><span class="txt">结算金额(元):</span>{{ basicInfo?.settlementAmount }}</div>
            </el-col>
          </el-row>
        </div>
        <div v-if="basicInfo?.orderSource == '2'">
          <el-row class="row">
            <el-col :span="8">
              <div class="col"><span class="txt">订单编号:</span>{{ basicInfo?.orderNum }}</div>
              <div class="col"><span class="txt">结算类型:</span>{{ basicInfo?.orderType == '1' ? '销售' : '退货' }}</div>
              <div class="col"><span class="txt">实收金额(元):</span>{{ basicInfo?.payAmount }}</div>
              <div class="col"><span class="txt">运费加价(元):</span>{{ basicInfo?.shopAddFreight }}</div>
              <div class="col"><span class="txt">运费(元):</span>{{ basicInfo?.freightAmount }}</div>

            </el-col>
            <el-col :span="8">
              <div class="col"><span class="txt">订单时间:</span>{{ basicInfo?.orderTime }}</div>
              <div class="col"><span class="txt">支付类型:</span>{{ getOrderTypeText(basicInfo?.payType) }}</div>
              <div class="col"><span class="txt">进货金额(元):</span>{{ basicInfo?.supplyAmount }}</div>
              <div class="col"><span class="txt">结算金额(元):</span>{{ basicInfo?.settlementAmount }}</div>
            </el-col>
            <el-col :span="8">
              <div class="col"><span class="txt">订单类型:</span>{{ basicInfo?.orderSource == '1' ? '线下订单' : '线上订单' }}</div>
              <div class="col"><span class="txt">订单金额(元):</span>{{ basicInfo?.orderAmount }}</div>
              <div class="col"><span class="txt">骑手配送费(元):</span>{{ basicInfo?.deliveryAmount }}</div>
              <div class="col"><span class="txt">打包费(元):</span>{{ basicInfo?.packAmount }}</div>

            </el-col>
          </el-row>
        </div>
      </div>
      <!-- 商品信息 -->
      <div class="detail">
        <h4 class="title">商品信息：</h4>
        <!-- 表格 -->
        <div class="globle_table tableBox">
          <el-table :data="tableData" style="width: 100%" height="500">
            <el-table-column label="序号" width="80" show-overflow-tooltip>
              <template slot-scope="scope">
                {{ scope.$index + 1 }}
              </template>
            </el-table-column>
            <el-table-column min-width="120" show-overflow-tooltip prop="supplierName" label="供应商名称"></el-table-column>
            <el-table-column min-width="120" show-overflow-tooltip prop="barCode" label="商品条形码"></el-table-column>
            <el-table-column min-width="120" show-overflow-tooltip prop="productName" label="商品名称"></el-table-column>
            <el-table-column min-width="120" show-overflow-tooltip prop="specs" label="规格"></el-table-column>
            <el-table-column min-width="120" show-overflow-tooltip prop="categoryName" label="商品类目"></el-table-column>
            <el-table-column min-width="120" show-overflow-tooltip prop="brand" label="品牌"></el-table-column>
            <!-- 购买数量 -->
            <el-table-column min-width="120" show-overflow-tooltip prop="productCount" label="购买数量"
              v-if="detail?.orderType == 1"></el-table-column>
            <el-table-column min-width="120" show-overflow-tooltip prop="productCount" label="退货数量"
              v-if="detail?.orderType == 2"></el-table-column>


            <!-- 销售价格---退货价格--- -->
            <el-table-column min-width="120" show-overflow-tooltip prop="productPrice" label="销售价(元)"
              v-if="detail?.orderType == 1"></el-table-column>

            <!-- 退货 -->
            <el-table-column min-width="120" show-overflow-tooltip prop="productAmount" label="退货总价(元)"
              v-if="detail?.orderType == 2"></el-table-column>

            <!-- ----进货价格--- -->
            <!-- 销售 -->
            <el-table-column min-width="120" show-overflow-tooltip prop="supplyPrice" label="进货价(元)"
              v-if="detail?.orderType == 1"></el-table-column>
            <!-- 退货 -->
            <el-table-column min-width="120" show-overflow-tooltip prop="supplyPrice" label="进货总价(元)"
              v-if="detail?.orderType == 2"></el-table-column>



            <el-table-column min-width="120" show-overflow-tooltip prop="discountAmount" label="总优惠金额(元)"
              v-if="detail?.orderType == 1"></el-table-column>
            <el-table-column min-width="120" show-overflow-tooltip prop="settlementAmount"
              label="结算金额(元)"></el-table-column>
          </el-table>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      basicInfo: {},//基本信息
      tableData: [],
    }
  },

  created() {
    this.getRequest()
  },
  methods: {
    // 请求
    getRequest() {
      this.loading = true;
      this.$axios.get(this.$api.getSettlementInfoDetail, {
        params: {
          id: this.$route.query.id
        }
      }).then((res) => {
        if (res.data.code == 100) {
          this.basicInfo = res.data.result;
          this.tableData = res.data.result.settlementDetailVoArrayList;
        }
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      })
    },
    goBack() {
      this.$router.go(-1)
    },
    // 支付类型
    getOrderTypeText(status) {
      switch (String(status)) {
        case '0':
          return '现金支付';
        case '1':
          return '线上支付';
        case '3':
          return '会员卡支付';
        default:
          return;
      }
    },
    // 点击按钮预览图片
    handleCommand(row) {
      this.url = row.picUrl;
      // this.url = 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg';
      this.showViewer = true
    },
    // 关闭查看器
    closeViewer() {
      this.showViewer = false
    },
  },
}
</script>

<style scoped lang='scss'>
.det {
  padding: 0 30px;

  .detail {
    padding-top: 20px;
    border-bottom: 1px solid rgba(3, 16, 14, 0.06);

    .title {
      font-size: 16px;
      margin-bottom: 15px;
    }

    .row {
      color: #707070;
      // font-size: 16px;

      .col {
        margin-bottom: 20px;
        // margin-right: 10px;
        font-size: 16px;
      }

      .order-code {
        padding-right: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .pagination {
      margin-bottom: 30px;
    }

  }



}
</style>